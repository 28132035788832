@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-info: #56208c;
  --toastify-text-color-light: rgb(107 114 128);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  max-width: 100vw;
}

[data-placeholder]::after {
  content: ' ';
  box-shadow: 0 0 50px 9px rgba(254, 254, 254);
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  animation: load 1s infinite;
}
@keyframes load {
  0% {
    left: -100%;
  }
  100% {
    left: 150%;
  }
}

.InputContainer .InputElement {
  line-height: 1.75rem !important;
}

.react-datepicker {
  @apply lg:grid lg:grid-cols-2 lg:gap-4;
}

.react-datepicker__input-container input {
  @apply form-input block w-full rounded border border-gray-300 bg-white text-base md:text-sm;
}

.react-datepicker-popper {
  @apply z-40 w-72 rounded border border-gray-200 bg-white px-3 py-2 text-sm lg:w-[576px];
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute top-12 right-0 z-10 w-72 transform-none rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__time-container {
  @apply box-border flex flex-col pb-3;
}

.react-datepicker__header--time {
  @apply mt-3 border-t border-gray-200 p-2 pt-4 text-lg text-gray-700 lg:mt-0 lg:border-t-0 lg:pt-2;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-center text-xs font-medium text-gray-400;
}

.react-datepicker__day-name {
  @apply flex h-8 w-8 items-center justify-center rounded-full py-1;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded py-1 text-sm leading-loose text-gray-700 transition hover:bg-gray-200;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply border-purple border-2 bg-white;
}

.react-datepicker__day--selecting-range-end {
  @apply border-purple border-2 bg-white;
}

.react-datepicker__day--selected {
  @apply bg-purple text-white;
}

.react-datepicker__day--range-start {
  @apply bg-purple text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__day--range-end {
  @apply bg-purple text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__time-list {
  @apply max-h-72 overflow-scroll;
}

.react-datepicker__time-list-item {
  @apply mb-1 cursor-pointer rounded px-2 text-sm leading-loose text-gray-700 transition hover:bg-gray-200;
}

.react-datepicker__time-list-item--selected {
  @apply bg-purple text-white;
}
